import React from 'react';
import './page2.scss';
import logo from './assets/iphone.png'; // Tell webpack this JS file uses this image
import paper from './assets/paper.svg';

function Page2() {
  return (
    <div className="page2" id="works">
      <div >
        <h3>
          <span>WORKS </span></h3>
      </div>
      <div className="page2Container">

        <div className="text-container">

          <div className="intro">
            <div className="sub-head">
              Fluto
          </div>
          Online Premium<br />
          Clothing Application

        </div>
          <div className="download">
            <a href="https://xd.adobe.com/view/48f03aa5-667c-4fe3-4333-601d5c0a725f-3ec1/?fullscreen" target="_blank">
              View Project
            </a>
          </div>
          <img src={paper} />
        </div>
        <div className="img-container">
          <img src={logo} />
        </div>
      </div>
    </div>


  );
}

export default Page2;
