import React from 'react';
import './page5.scss';
import logo from './assets/andriod.png'; // Tell webpack this JS file uses this image

function Page5() {
  return (
    <div className="page5">
    <div className="page5Container">
    <div className="img-container">
        <img src={logo} />
      </div>
      <div className="text-container">
        
        <div className="intro">
          <div className="sub-head">
            Buzz
       </div>
       Online Movie<br/>
Streaming Application
      </div>
        <div className="download">
          <a href="https://xd.adobe.com/view/1c53c8c6-187d-4ac7-6b56-357b0cb85ce9-6a62/?fullscreen" target="_blank">
            View Project
          </a>
        </div>
      </div>
      
    </div>
  </div>
       
  );
}

export default Page5;
