import React from 'react';
import './page3.scss';
import logo from './assets/ipad.png'; // Tell webpack this JS file uses this image

function Page3() {
  return (
    <div className="page3">
      <div className="page3Container">
        <div className="img-container">
          <img src={logo} />
        </div>
        <div className="text-container">

          <div className="intro">
            <div className="sub-head">
              Blood bank
         </div>
         Blood Bank’s Internal<br />
Staff Communication
        </div>
          <div className="download">
            <a href="https://xd.adobe.com/view/2ffff807-2163-434e-7193-1918a65540b2-59ce/?fullscreen" target="_blank">
              View Project
            </a>
          </div>
        </div>

      </div>
    </div>

  );
}

export default Page3;
