import React from 'react';
import ReactTooltip from "react-tooltip";
import { useToasts } from 'react-toast-notifications'

import './page6.scss';



function Page6() {
  const { addToast, removeAllToasts } = useToasts()
  
  const CopyToClipboard =  () => {
  
    const containerid = 'copytext';
    if (document.selection) {
      const range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select().createTextRange();
      document.execCommand("copy");
    } else if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      window.getSelection().addRange(range);
      document.execCommand("copy");
    }
    removeAllToasts();
    addToast('Mail id Copied To Clipboard', { appearance: 'success' })

  }

  return (
    <div className="page6" id="about">
        <ReactTooltip />
      <h3 className="title">ABOUT ME</h3>

      <div className="text-container">
        <div className="sub-head">
          My Working Process
       </div>
        <div className="text-content">
          Hey, I am an innovative, strongly determined and passionate UX/UI Designer to develop intuitive interfaces and transformative <br />
       user experiences to connect customers and employees to Minimum Viable Products (MVPs) of digital solutions. Proficiency with<br />
       common design tools, experience from translating end-user / customer requirements into an integrated customer journey, strong<br />
       communication skills to discuss feedback and requirements with end-users, and a strong drive and willingness to learn on the job.
       </div>
        <br />
        <div className="text-content">
          I follow the Lean UX and User-centred Design Thinking process to make sure that my design decisions were supported by user<br />
       research and feedback. I studied in different places like India, Europe and my ability to quickly adjust to new challenges in life<br />
       comes from that. Owing to my diverse upbringing, I embrace all cultures and try to bring it to the designs I create.
       </div>
        <br />
        <br />
        <div className="sub-head">
          Things I would like to do
       </div>
        <div className="text-content">
          Photography, Horse Riding, Karate, Play Tennis and watch movies.
       </div>
        <div style={{ width: "1px", height: "1px", overflow: 'hidden' }} id="copytext">krishnateja.velugubantla@gmail.com</div>
        <button data-tip="Click to copy email" onClick={CopyToClipboard}>Let's Talk</button>
        </div>
    
    </div>
    
  );
}

export default Page6;
