import React from 'react';
import './page7.scss';
import behance from './assets/Behance.svg';
import linkedin from './assets/Icon-awesome-linkedin.svg';
import medium from './assets/Icon-awesome-medium.svg';

function Page7() {
  return (
    <div className="page7" id="contact">
      <h3 className="title">Let's connect</h3>

      <div className="sub-text">
        <div>Feel free to reach out for collaborations or just a friendly hello</div>
        <div>krishnateja.velugubantla@gmail.com</div>
      </div>
     
      <div className='contact-img' > 
        <a href="https://www.linkedin.com/in/krishna-teja-056374158/" target="_blank">
           <img src={linkedin} />
        </a>
        <a href="https://www.behance.net/krishnateja2" target="_blank">
          <img src={behance} />
        </a>  
        <a href= "https://medium.com/@Krishna_Teja_V" target="_blank">
          <img src={medium} />
        </a>
      </div>
    </div>
  );
}

export default Page7;
