import React from 'react';
import './page4.scss';
import logo from './assets/Macbook.png'; // Tell webpack this JS file uses this image

function Page4() {
  return (
    <div className="page4">

      <div className="page4Container">
     
        <div className="text-container">
         
          <div className="intro">
            <div className="sub-head">
              Hello Fello
         </div>
          A Design School<br />
          Website
        </div>
          <div className="download">
            <a href="https://xd.adobe.com/view/53aa9c78-e0be-4670-44e6-267287c3d4e9-259b/?fullscreen" target="_blank">
              View Project
            </a>
          </div>
        </div>
        <div className="img-container">
          <img src={logo} />
        </div>
      </div>
    </div>

  );
}

export default Page4;
