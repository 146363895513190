import React from 'react';
import { ToastProvider } from 'react-toast-notifications'

import './App.scss';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import Page6 from './Page6';
import Page7 from './Page7';
import arrow from './assets/Arrow.svg';



class App extends React.Component {

  state = {
    bottom: -100
  }
  componentDidMount(){
    var self = this;
    window.addEventListener('scroll', function(e) {
      const topValue = 1000;
      if (document.body.scrollTop > topValue || document.documentElement.scrollTop > topValue) {
        self.setState({bottom: 50});
      }
      else
      self.setState({bottom: -100});  
    });
  }

  moveToTop = () => {
  window.scroll({ 
    top: 0, // could be negative value
    left: 0, 
    behavior: 'smooth' 
  });
  }
  render(){
    return (
      <ToastProvider>
        <div className="App container-fluid">
          <a onClick={this.moveToTop} className="movetotop" style={{bottom: this.state.bottom + "px"}}> 
            <img src={arrow} />
          </a>
          <Page1 />
          <Page2 />
          <Page3 />
          <Page4 />
          <Page5 />
          <Page6 />
          <Page7 />
        </div>
      </ToastProvider>
    );
  }
}

export default App;
