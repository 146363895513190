import React, { useState } from 'react';
import './page1.scss';
import logo from './assets/qq.jpg'; // Tell webpack this JS file uses this image
import resume from './assets/resume.pdf';

class Page1 extends React.Component {

  state = {current: 3};

  componentDidMount(){
    var self = this;
    window.addEventListener('scroll', function(e) {
      let currentScreen = null;
      if(window.outerWidth === 768){
        currentScreen = {contact: {min: 3501}, about: {min: 2850, max: 3500}, works: {min: 341, max: 2849}, download: {min: 340}};
      }
      else if(window.outerWidth === 1024 && window.outerHeight === 768){
        currentScreen = {contact: {min: 4716}, about: {min: 3616, max: 4715}, works: {min: 170, max: 3615}, download: {min: 169}};
      }
      else if(window.outerWidth === 1024 && window.outerHeight === 1366){
        currentScreen = {contact: {min: 4129}, about: {min: 3616, max: 4128}, works: {min: 170, max: 3615}, download: {min: 169}};
      }
      else if(window.outerWidth === 1366 && window.outerHeight === 1024){
        currentScreen = {contact: {min: 5627}, about: {min: 4494, max: 5626}, works: {min: 170, max: 4493}, download: {min: 169}};
      }
      else {
        currentScreen = {contact: {min: 5500}, about: {min: 4525, max: 5550}, works: {min: 730, max: 4500}, download: {min: 700}};
      }
      const scrollTop = document.documentElement.scrollTop;
      console.log(scrollTop);
      if (scrollTop <= currentScreen.download.min) {
        self.setState({current: 3});
      }
      else if (scrollTop > currentScreen.works.min &&  scrollTop <= currentScreen.works.max ) {
        self.setState({current: 2});
      }
      else if (scrollTop > currentScreen.about.min &&  scrollTop <= currentScreen.about.max) {
        self.setState({current: 1});
      }
      else if (scrollTop >= currentScreen.contact.min) {
        self.setState({current: 0});
      }
      else{
        self.setState({current: -1});
      }
    });
  }

  render(){
    const {current} = this.state;
    return (
      <div className="page1" id="download-resume">
        <div className="side-items">
        
          <a href="#contact" className={current === 0 ? 'selected-area' : ''} onClick={() => this.setState({current:0})}>Contact</a>
          <a href="#about" className={current === 1 ? 'selected-area' : ''} onClick={() =>this.setState({current:1})}>About me</a>
          <a href="#works" className={current === 2 ? 'selected-area' : ''} onClick={() => this.setState({current:2})}>Works
          </a>
          <a href="#download-resume" className={current === 3 ? 'selected-area' : ''} onClick={() => this.setState({current:3})}>Resume
          </a>
        </div>
        <div className="text-container" >
          <div> 
            <h3>
              <span>Hello, I’m </span>Krishna.</h3>
          </div>
          <div className=" intro"> I am innovative, strongly determined and <br className="hide"/>
          passionate UX/UI Designer. I enjoy creating <br className="hide"/>
          intuitive interfaces, user-centric designs, <br className="hide"/>
          and human experiences.</div>
         <div className="download">
         <a href={resume} target="_blank">
            Download Resume
          </a>
         </div>
          </div>
        <div className="img-container">
          <img src={logo} />
        </div>
        
      </div>
  
    );
  }
}

export default Page1;
